import Img from 'gatsby-image'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player/file'

gsap.registerPlugin(ScrollTrigger)

const CustomImg = ({
  localFile,
  alt,
  className,
  style,
  imgStyle,
  fadeIn,
  loading,
}) => {
  // const fadeInDefault = fadeIn || true

  // Ref to fix "Can't perform a React state update on an unmounted component." error
  const isMountedRef = useRef(null)

  const mp4Ref = useRef(null)
  const [videoPlaying, setVideoPlaying] = useState(false)

  // useEffect(() => {
  //   console.log(videoPlaying)
  // }, [videoPlaying])

  // useEffect(() => {
  //   ScrollTrigger.refresh()
  // }, [mp4Ref.current])

  // TODO: Might need to add an isMounted ref in this useEffect to prevent "Warning: Can't perform a React state update on an unmounted component." See SectionCard.js for an example.
  useEffect(() => {
    isMountedRef.current = true // Component is mounted
    // ScrollTrigger.refresh()
    if (mp4Ref.current !== null) {
      // console.log(mp4Ref.current)

      ScrollTrigger.create({
        id: `mp4-trigger`,
        trigger: mp4Ref.current,
        start: 'top center',
        end: 'bottom center',
        // markers: true,
        onEnter: () => {
          isMountedRef.current && setVideoPlaying(true)
        },
        onEnterBack: () => {
          isMountedRef.current && setVideoPlaying(true)
        },
        onLeave: () => {
          isMountedRef.current && setVideoPlaying(false)
        },
        onLeaveBack: () => {
          isMountedRef.current && setVideoPlaying(false)
        },
      })
    }
    return () => {
      isMountedRef.current = false // Flag the component as unmounted, so that it doesn't try to do any state updates
    }
    // FIXME the dependency probably isn't working like with thought it would
    // eslint-disable-next-line
  }, [mp4Ref.current])

  // //FIXME: The log shows that every image, not just videos, is changing the videoPlaying state. Why???
  // useEffect(() => {
  //   console.log(videoPlaying)
  // }, [videoPlaying])

  if (localFile === null) {
    //console.log(alt)
    return null
  }

  // FIXME there's a console error:
  // Warning: Expected server HTML to contain a matching <picture> in <div>.
  //   at picture
  //   at div
  //   at Image (webpack-internal:///./node_modules/gatsby-image/index.js:312:30)
  //   at CustomImg (webpack-internal:///./src/components/global/CustomImg.js:27:5)
  if (
    localFile.ext === null ||
    typeof localFile.ext === 'undefined' ||
    localFile.ext === '.png' ||
    localFile.ext === '.jpg' ||
    localFile.ext === '.jpeg'
  ) {
    // JPEGs and PNGs
    return (
      <Img
        fluid={localFile.childImageSharp.fluid}
        alt={alt}
        className={className}
        style={style}
        onLoad={() => {
          //ScrollTrigger.refresh() // Make sure Scroll Trigger markers are in the right place after full-size images have loaded
        }}
        imgStyle={imgStyle}
        fadeIn={fadeIn}
        loading={loading}
      />
    )
  } else if (localFile.ext === '.mp4') {
    // Movie
    if (
      // Check if the classname includes 'tintedImage'
      typeof className !== 'undefined' &&
      className.includes('tintedImage') !== false
    ) {
      //console.log("don't try to use movies as bgs")
      return null
    } else if (
      // Check if the classname includes 'lightbox'
      typeof className !== 'undefined' &&
      className.includes('lightbox') !== false
    ) {
      return (
        <div className={className}>
          <ReactPlayer
            className={`lightboxVideo`}
            width="100%"
            height="100%"
            url={localFile.publicURL}
            alt={alt}
            playsinline // Prevent Vimeo from automatically entering fullscreen
            playing={true}
            loop={true}
            autoPlay
            volume={1} // Required for Muted to work
            muted={true} // Autoplay videos must be muted
          />
        </div>
      )
    } else {
      return (
        <div className={className} ref={mp4Ref}>
          <ReactPlayer
            //className={`${styles.reactPlayer}`}
            width="100%"
            height="100%"
            url={localFile.publicURL}
            alt={alt}
            playsinline // Prevent Vimeo from automatically entering fullscreen
            playing={videoPlaying}
            loop={true}
            // autoPlay
            volume={1} // Required for Muted to work
            muted={true} // Autoplay videos must be muted
            style={{ objectFit: 'contain' }}
            // config={{
            //   vimeo: {
            //     color: accent, //TODO: set up color for funsies!
            //   },
            // }}
            onReady={() => {
              ScrollTrigger.refresh() // try to position triggers correctly
              // console.log('video ready')
            }}
          />
        </div>
      )
    }
  } else {
    // Extention other that .mp4, .png, .jpg, .jpeg
    console.log(localFile.ext)
    return null
  }
}

CustomImg.propTypes = {
  localFile: PropTypes.object.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default CustomImg
